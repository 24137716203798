import { useRouter } from 'next/router';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { loginWithUserData } from '@store/auth/auth.actions';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import { useAppDispatch } from '@store/useAppDispatch';
import { getCookie } from 'cookies-next';
import type { GoogleResponse } from 'types/window';

import { selectAuthMethods, useAppConfigSelector } from '@hooks/useAppConfigSelectors';

const GoogleOneTap = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const token = getCookie('token');
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { google } = useAppConfigSelector(selectAuthMethods);
  const { clientId, isGoogleOneTapEnabled } = google?.parameters ?? {};

  const handleGoogleAuthCallback = (response: GoogleResponse) => {
    const { credential } = response;

    if (clientId && credential) return onSuccessGoogle(response);

    return onFailureGoogle(response);
  };

  const onSuccessGoogle = ({ clientId, credential }: GoogleResponse) => {
    dispatch(
      loginWithUserData({
        tokenId: credential,
        id: clientId,
        type: 'google',
        keepMeLoggedIn: true,
        pagePath: router.asPath,
      })
    ).then(unwrapResult);
  };

  const onFailureGoogle = (error: unknown) => {
    console.log(error);
  };

  useEffect(() => {
    if (
      window &&
      window.google &&
      google?.enabled &&
      !token &&
      !isAuthenticated &&
      isGoogleOneTapEnabled &&
      !router?.pathname.includes('auth')
    ) {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleGoogleAuthCallback,
      });

      window.google.accounts.id.prompt();
    }
  }, []);

  return (
    <div
      id="g_id_onload"
      data-client_id={clientId}
      data-auto_prompt="false"
    ></div>
  );
};

export default GoogleOneTap;
